import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const BlackTypograpgy = styled(Typography)`
  color: black;
`;

const WhiteTypograpgy = styled(Typography)`
  color: white;
`;

const contacts = [
  {
    name: 'Kundai',
    phone: '+64273559307',
    email: 'ktome95@gmail.com',
  },
  {
    name: 'Farai',
    phone: '+6421682311',
    email: 'faraikaila@gmail.com',
  },
];

export const Contacts = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display={'flex'}
      width={'100%'}
      mt={theme.spacing(10)}
      flexDirection={matches ? 'column' : 'row'}
    >
      {contacts.map((contact, idx) => {
        const Typo = idx % 2 == 0 ? BlackTypograpgy : WhiteTypograpgy;
        return (
          <Box
            flex={1}
            sx={{ backgroundColor: 'black' }}
            border={'1px solid white'}
            key={contact.name}
          >
            <Box
              sx={{
                backgroundColor: idx % 2 == 0 ? 'white' : 'black',
                p: theme.spacing(5),
              }}
            >
              <Typo variant='h2' fontWeight={900}>
                {contact.name}
              </Typo>
              <Typo variant='h5' fontWeight={600}>
                {contact.phone}
              </Typo>
              <Typo variant='h5' fontWeight={600}>
                {contact.email}
              </Typo>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
