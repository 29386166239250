import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4d5d53',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    allVariants: {
      color: '#fff',
      accentColor: '#fff',
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {},
    },
    MuiButton: {
      styleOverrides: {},
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
  },
});
