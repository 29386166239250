import { EventType } from '../constants/EventType';

export const validateInvitationCode = async (code: string) => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  if (!apiUrl) {
    throw new Error('API URL not set');
  }

  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    body: JSON.stringify({
      'Event-Type': EventType.VALIDATE_INVITATION_CODE,
      code,
    }),
  })
    .then((res) => res.json())
    .catch(() => {
      throw new Error(
        'Invitation code not recognised. Please try again or contact us for help.'
      );
    });
};
