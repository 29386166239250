import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import { EventType } from '../../constants/EventType';
import { WhiteBorderTextField } from '../Common';

export const RsvpForm = ({
  postSubmitAction,
  invitationCode,
}: {
  postSubmitAction: () => void;
  invitationCode: string;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        margin: 'auto',
        width: matches ? '100%' : '500px',
        border: matches ? '' : '.5px solid white',
        mt: theme.spacing(10),
      }}
    >
      <Formik
        initialValues={{
          attending: true,
          guests: 1,
          dietaryRequirements: 'n/a',
          message: '',
          attendingWith: 'n/a',
          code: invitationCode,
        }}
        onSubmit={(values) => {
          const url = process.env.REACT_APP_API_URL || '';
          if (!url) {
            throw new Error('API URL not set');
          }

          if (values.attending && values.guests < 1) {
            throw new Error('Guests must be greater than 0');
          }

          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: '*/*',
            },
            body: JSON.stringify({
              ...values,
              'Event-Type': EventType.RSVP,
            }),
          }).then((response) => {
            postSubmitAction();
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                padding: theme.spacing(5),
              }}
            >
              <Box sx={{ mt: 3, width: '100%' }}>
                <Typography variant='h6' color={'white'}>
                  Will you be attending ? {values.attending ? `🥳` : `🥺`}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    aria-label='attending'
                    name='attending'
                    checked={values.attending}
                    onChange={handleChange}
                    color='secondary'
                    sx={{
                      '&.MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  />
                  <Typography color={'white'}>
                    {values.attending ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>

              {/* <WhiteBorderTextField
                fullWidth
                sx={{
                  mt: 3,
                  color: 'white',
                  fieldset: { borderColor: 'white' },
                  input: { color: '#fff' },
                }}
                name='dietaryRequirements'
                value={values.dietaryRequirements}
                variant='outlined'
                multiline
                minRows={3}
                // disabled={!values.attending}
                onChange={handleChange}
                label='Dietary Requirements'
                placeholder='Dietary Requirements'
              /> */}

              <WhiteBorderTextField
                fullWidth
                sx={{
                  mt: 3,
                  fieldset: { borderColor: 'white' },
                  input: { color: '#fff' },
                }}
                variant='outlined'
                name='message'
                value={values.message}
                multiline
                minRows={3}
                onChange={handleChange}
                label='Leave a note (optional)'
                placeholder={`Leave us a note if you'd like`}
              />

              <Button
                type='submit'
                variant='contained'
                color='secondary'
                size='large'
                sx={{ mt: 3, width: 'max-content', alignSelf: 'center' }}
                endIcon={
                  isSubmitting ? (
                    <CircularProgress sx={{ color: 'black' }} size={12} />
                  ) : null
                }
              >
                {isSubmitting ? 'Submitting' : 'Submit'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
