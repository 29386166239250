import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';
import { RsvpForm } from './RsvpForm';
import { Authenticator } from './Authenticator';
import { validateInvitationCode } from '../../utils/ApiService';
import { PageTitle } from '../Common';

export const Rsvp = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = theme.breakpoints.down('md');

  const validateCode = async (invitationCode: string): Promise<boolean> => {
    setIsValidating(true);
    return validateInvitationCode(invitationCode)
      .catch((error) => {
        setIsValidating(false);
        setError(error.message);
        throw error;
      })
      .then((_) => {
        setIsValidating(false);
        return true;
      });
  };

  return (
    <>
      <PageTitle title={'Rsvp'} />

      {authenticated ? (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <RsvpForm
            postSubmitAction={() => navigate('/submitted')}
            invitationCode={invitationCode}
          />
        </Box>
      ) : (
        <>
          <Authenticator
            isValidating={isValidating}
            validateInvitationCode={validateCode}
            error={error}
            isAuthenticated={(invitationCode) => {
              setAuthenticated(true);
              setInvitationCode(invitationCode);
            }}
          />
        </>
      )}
    </>
  );
};
