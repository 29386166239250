import { Box, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const menus = [
  { name: 'Rsvp', link: 'rsvp' },
  // { name: 'Contacts', link: 'contacts' },
  { name: 'Scoop', link: 'more-info' },
];
export const Header = () => {
  const theme = useTheme();
  return (
    <Box
      component={'header'}
      sx={{
        display: 'flex',
        height: theme.spacing(10),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'fill-available',
      }}
    >
      <Link to='/'>
        <Typography sx={{ color: ' white' }}>Home</Typography>
      </Link>
      <Box sx={{ display: 'flex' }}>
        {menus.map((menu) => {
          return (
            <Box sx={{ marginRight: 2 }} key={menu.name}>
              <Link to={menu.link}>
                <Typography sx={{ color: ' white' }}>{menu.name}</Typography>
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
