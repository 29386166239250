import {
  Box,
  Button,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import bg from '../../../images/couple_full.jpg';

export const SaveTheDate = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmalll = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));

  const containerMarginTop = isSmalll
    ? theme.spacing(10)
    : isMedium
    ? theme.spacing(20)
    : theme.spacing(30);

  return (
    <Box
      sx={{
        padding: 3,
        textAlign: 'center',
        maxWidth: '500px',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
      }}
    >
      <Typography
        fontFamily={'Clicker Script'}
        fontSize={theme.spacing(10)}
        lineHeight={1.1}
        fontWeight={700}
        my={5}
        sx={{
          marginTop: containerMarginTop,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        Kundai
        <br />& Farai
      </Typography>

      <Typography fontFamily={'krub'} variant='h5' my={theme.spacing(5)}>
        Are getting married and you are invited !
      </Typography>

      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Chip
          label='October 26, 2024'
          variant='outlined'
          sx={{
            color: 'white',
            padding: theme.spacing(2),
            fontWeight: 600,
            my: theme.spacing(2),
            width: 'fit-content',
          }}
          size='medium'
        />

        <Chip
          label='Harare, Zimbabwe'
          variant='outlined'
          sx={{
            color: 'black',
            backgroundColor: 'white',
            padding: theme.spacing(2),
            fontWeight: 600,
            my: theme.spacing(2),
          }}
          size='medium'
        />
      </Box>

      <Button
        variant='outlined'
        sx={{
          border: '1px solid white',
          color: 'white',
          borderRadius: 0,
          my: 5,
        }}
        size='large'
        onClick={() => navigate('/rsvp')}
      >
        RSVP
      </Button>

      <Box sx={{ display: 'flex', flexDirection: 'column' }} my={3}>
        <Typography fontFamily={'krub'} variant='h6'>
          We are so excited to have you here as we count down to our big day! We
          will be adding more information on the website in due time with
          details about the venue and other important information. Don't forget
          to RSVP so we can plan accordingly and ensure you have a fantastic
          time with us. Your presence means the world to us, and we can't wait
          to share this joyous occasion with you. Thank you for being a part of
          our story!
        </Typography>
      </Box>
    </Box>
  );
};
