import './App.css';
import {
  Contacts,
  Home,
  MoreInfo,
  OurStory,
  Rsvp,
  Submitted,
} from './components';
import { Route, Routes } from 'react-router-dom';
function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/index.html' element={<Home />} />
      <Route path='/submitted' element={<Submitted />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/more-info' element={<MoreInfo />} />
      <Route path='/our-story' element={<OurStory />} />
      <Route path='/rsvp' element={<Rsvp />} />
    </Routes>
  );
}

export default App;
