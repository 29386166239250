import { Box, Typography } from '@mui/material';
import { PageTitle } from '../Common';

const moreInfo = [
  { title: 'Date', content: 'Saturday, 26th October 2024' },
  { title: 'Time', content: 'Ceremony: 11:00 am' },
  {
    title: 'Dress Code',
    content:
      'Garden cocktail friendly, formal or smart casual, but most importantly, to your taste!',
  },
  {
    title: 'Gifts',
    content:
      'Your presence is the best gift we can hope for. However, if you would like to honour us with a gift, please keep  it to cash only as we are travelling light.',
  },
  {
    title: 'Accommodation',
    content:
      'For those travelling from outside Harare, let us know if you need help securing accommodation.',
  },
  {
    title: 'RSVP',
    content:
      'Please RSVP by 15th of August 2024. We know it is a long way off but we need to know numbers to ensure everyone is looked after.',
  },
  {
    title: 'Contacts',
    content:
      'For any queries, please contact us on +64273559307 (Kundai) or +6421682311 (Farai).',
  },
  {
    title: 'Location',
    content:
      'Padonhodzo venue, 334 Sunninghill Close Glenlorne, Harare, Zimbabwe',
  },
];
export const MoreInfo = () => {
  return (
    <Box mt={6}>
      <PageTitle title={'The Scoop'} />
      {moreInfo.map((info) => {
        return (
          <Box key={info.title} display={'flex'} mb={4}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                px: 2,
                alignItems: 'center',
              }}
            >
              <Typography fontWeight={600}>{info.title}</Typography>
            </Box>
            <Box
              sx={{
                flex: 5,
                display: 'flex',
                justifyContent: 'flex-start',
                px: 2,
                borderLeft: 1,
              }}
            >
              <Typography>{info.content}</Typography>
            </Box>
          </Box>
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30397.38795891028!2d31.124438956156006!3d-17.760017100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931b09c2062c32f%3A0x9f9173da9908b8b4!2sSunninghill%20Cl%2C%20Harare%2C%20Zimbabwe!5e0!3m2!1sen!2snz!4v1725413940281!5m2!1sen!2snz'
          width='100%'
          height='450'
          // style='border:0;'
          // allowfullscreen=''
          loading='lazy'
          // referrerpolicy='no-referrer-when-downgrade'
        ></iframe>
      </Box>
    </Box>
  );
};
