import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../Header';

export const AppContainer = ({ children }: { children: any }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
        minHeight: '100vh',
        width: '100%',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1200px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Header />
        {children}
      </Box>
    </Box>
  );
};
