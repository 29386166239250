import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

export const Submitted = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        width: matches ? '100%' : '50%',
        marginX: 'auto',
      }}
    >
      <Box textAlign={'center'} padding={3}>
        <Typography
          sx={{
            color: 'white',
            fontSize: '2em',
            mb: 5,
          }}
        >
          Thank you for taking the time to RSVP!
        </Typography>

        <Typography sx={{ color: 'white' }} variant='h6' mt={3}>
          For those who can make it, we can’t wait to celebrate this special day
          with you. For those who cannot, we’ll be thinking of you and look
          forward to sharing memories and photos with you. Also, kindly note
          that the event is strictly by invitation only, and we ask that you
          leave the little ones at home 😊.
          <br />
          With heartfelt gratitude,
          <br />
          <br />- Kundai and Farai
        </Typography>
      </Box>
    </Box>
  );
};
