import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import KeyIcon from '@mui/icons-material/Key';
import { WhiteBorderTextField } from '../Common';

export const Authenticator = ({
  isAuthenticated,
  validateInvitationCode,
  isValidating,
  error,
}: {
  isAuthenticated: (invitationCode: string) => void;
  validateInvitationCode: (invitationCode: string) => Promise<boolean>;
  error: string;
  isValidating: boolean;
}) => {
  const [invitationCode, setInvitationCode] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        display={'flex'}
        width={'100%'}
        justifyContent={'center'}
        marginTop={theme.spacing(10)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing(5),
            margin: matches ? theme.spacing(3, 5) : theme.spacing(5, 10),
          }}
        >
          <WhiteBorderTextField
            label='Invitation code'
            variant='outlined'
            placeholder='Invitation code ...'
            required={false}
            error={!!error}
            helperText={error}
            value={invitationCode}
            onChange={(e) => setInvitationCode(e.target.value)}
            sx={{
              fieldset: { borderColor: 'white' },
              input: { color: '#fff' },
            }}
          />
          <Button
            variant='contained'
            color='secondary'
            sx={{
              mt: 4,
              height: 'max-content',
              padding: '1em',
            }}
            endIcon={
              isValidating ? (
                <CircularProgress sx={{ color: 'black' }} size={12} />
              ) : (
                <KeyIcon sx={{ color: 'black' }} />
              )
            }
            onClick={() => {
              if (!invitationCode) return;
              validateInvitationCode(invitationCode).then(
                (validated) => validated && isAuthenticated(invitationCode)
              );
            }}
          >
            Enter
          </Button>
        </Box>
      </Box>
    </>
  );
};
